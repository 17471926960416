.form-signin {
  width: 100%;
  max-width: 500px;
  padding: 15px;
  margin: auto;
  text-align: center;
}

.form-signin .checkbox {
  font-weight: 400;
}

.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}

.form-signin .form-control:focus {
  z-index: 2;
}

.form-control:focus {
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.form-signin input[type="email"] {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.login-email-input{
  margin-top:20px;
  margin-bottom: 28px;
}

.registration-title{
  font-family: 'Games', sans-serif;
}

.registration-fields-container{
  margin-top:30px;
} 

.form-error-component {
  text-align: left;
  color: rgb(250, 65, 45);
  margin-top:4px;
  margin-bottom:8px;
  min-height:16px;
  line-height: 16px;
}

.register-button{
  margin-top:40px;
  background-color: #fff;
  border-color: rgb(68, 62, 62);
  color: rgb(68, 62, 62);
  font-family: 'Games', sans-serif;
}

.register-button:hover{
  background-color: rgb(68, 62, 62);
  border-color:  rgb(0, 0, 0);
  color: #fff;
}

.register-button:focus{
  background-color:  rgb(67, 64, 64);
  border-color: rgb(67, 64, 64);
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.19);
}

.register-button:disabled{
  background-color:  rgb(18, 17, 17);
  border-color: rgb(67, 64, 64);
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.19);
}

@media screen and (max-width: 800px) {
  .left, .main, .right {
    width: 100%; /* The width is 100%, when the viewport is 800px or smaller */
  }
}