body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* THIS COLOUR LOOKS GOOD ASWELL */
  /* background-color: gainsboro  */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

ul {
  list-style-type: none;
  padding: 0px;
  margin: 0px;
}

input:focus {
  outline: none;
}

.navbar {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  height: 8vh;
}

.play-link {
  font-weight: 600;
}

@media screen and (max-width: 800px) {
  .navbar{
    flex-wrap: nowrap;
  }
  .navbar-nav {
    flex-direction: row;
    justify-content: space-around;
    flex-wrap:nowrap;
    margin: 0;
  }
  .pages-link {
    align-items: center;
    margin: 0;
    justify-content: space-evenly;
    width: 100%;
  }
}

/* GAME CARD */
.form-game-card {
  width: 100%;
  width: 700px;
  bottom: 20vh;
  padding-top: 20vh;
}
.form-game-card .checkbox {
  font-weight: 400;
}
.form-game-card .form-control {
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
.form-game-card .form-control:focus {
  z-index: 2;
}
.form-game-card input[type="answer"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-game-card input[type="answer"]:focus {
  outline: black;
}
.game-card-form-label {
  font-size: 30px;
}
.correct-answer {
  color: green;
}
.incorrect-answer {
  color: red;
}
.correct-answer:focus {
  color: green;
}
.incorrect-answer:focus {
  color: red;
}

.game-page-container{
  display: flex;
  justify-content: space-evenly;
  padding-top: 3vh;
  padding-left: 1vh;
  padding-right: 1vh;
}

/* GAME BUTTONS */
.button-container {
  display: flex;
  flex-direction: column;
}

.keyboard-container {
  margin-top: 2em;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}

.skip-submit-container{
  display:flex;
  justify-content: space-between;
}

.game-input-container {
  padding-left:2em;
  padding-right:2em;
  justify-content: space-around;
}

  /* GAME STATS CARD */
.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 20em;

}
.card li {
}

/* QUESTION STATS CARD */
.game-stats-card {
  margin-top: 0vh;
  width: 20em;
}
.game-stat-label {
  font-weight: 600;
}

.ratings {
}
.ratings-correct-answer {
  color: green;
  font-weight: 600;
}
.ratings-incorrect-answer {
  color: #e32636;
  font-weight: 600;
}

@media screen and (max-width: 1000px) {
  .game-page-container{
    display: flex; 
    flex-flow: column;
    width: 100%;
  }

  .game-input-container {
    padding-top: 0px;
    order:0;
  }

  .game-stats-card {
    order:1;
    margin:auto;
   }

  .form-game-card {
    order:3;
    width:100%;
  }

  .question-stats-card {
    margin:auto;
  }

}

/* LOADING SPINNER */
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.spinner-container {
  display: flex;
  align-items: center;
  margin: 10vh;
}
.loading-spinner {
  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 10px solid #383636; /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

/* PROFILE PAGE */

.full-profile-page {
  background-color: #eee;
  height: 100vh;
}

.profile-page-container {
  display: flex;
  flex-direction: row;
  margin: 50px;
}

.profile-card-container {
  background-color: #fff;
  width: 390px;
  height: 450px;
  max-width: 360px;
  border-radius: 10px;
  text-align: center;
  padding: 20px;
}

.profile-avatar {
  border-radius: 50%;
  width: 210px;
  height: 210px;
}

.elo-rating {
  color: rgb(68, 62, 62);
}

.user-name {
  color: rgb(68, 62, 62);
  padding-top: 10px;
  margin: 0px;
}

.user-info-container {
  color: rgb(117, 117, 117);
  padding: 10px;
  margin: 0px;
}

.profile-details-container {
  width: 100%;
  padding: 0px;
  margin-top: 0px;
  margin-left: 35px;
}

.streak-tiles {
  background-color: #fff;
  height: 300px;
  border-radius: 10px;
  padding: 15px;
}

.profile-details {
  color: rgb(117, 117, 117);
  background-color: #fff;
  margin-top: 30px;
  border-radius: 5px;
  padding: 30px;
}

.account-info-item {
  padding: 5px;
  display: flex;
  flex-direction: row;
}

.card-field {
  width: 250px;
  color: rgb(68, 62, 62);
}



/* ANALYTICS PAGE */
.analytics-page {
  height: 100vh;
  width: 100%;
  background-color: #eee;
}

.analytics-container {
  margin:70px;
  margin-top:30px;
}

.card {
  border-radius: 3px;
  border-style: solid;
  border-color: rgb(68, 62, 62);
  border-width: 1px;
  color: rgb(68, 62, 62);
}

.dashboard-title {
  margin-bottom: 30px;
  color: rgb(68, 62, 62);
}

.stats-cards-container{
  display:flex;
  flex-direction:row;
  text-align: center;
  justify-content: space-between;
}

.stats-card{
  padding:2rem;
  height: 150px;
  width:20%;
  background-color: #fff;
}

.graph-charts-container {
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 30px;
  text-align: center;
  align-items: flex-start;
}

.elo-chart-title {
  border-bottom: 1px solid;
  border-color: rgb(68, 62, 62);
  height: 50px;
  line-height: 50px;
  font-weight: 600;
  color: rgb(68, 62, 62)
}

.elo-area-chart {
  min-width: 650px;
  background-color: #fff;
}

.correct-pie-chart{
  max-width:30%;
  background-color: #fff;
  margin-left: 30px;
  height: 325px;
  display:flex;
  align-items: center;
}
.wrong-pie-chart{
  max-width:30%;
  background-color: #fff;
  margin-left: 30px;
  height: 325px;
  display:flex;
  align-items: flex-end;

}

.chart {
  display:flex;
  align-items: flex-end; 
}

.home-page-container {
  display: flex;
  flex-wrap: wrap;
  max-width: 1100px;
  margin: auto;
  height: 90vh;
}

.home-details{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  color: #383636;
  font-size:1.75rem;
}

.home-box {
  padding: 10px;
  flex-grow: 1;
  max-width: 300px;
  /* border: 1px solid;
  box-sizing: border-box; */
  border-radius: 3px;
  background-color: #fff;
  font-weight: bold;
}

.home-section{
  width: 100%;
  min-height: 100vh;
  flex-direction: column;
  justify-content:space-between;
  align-items: center;
  margin:auto;
  padding: 80px 80px 0px 80px;
  display: flex;
}

.more-details {
  background-color: #212529;
  color:#fff;
  display: flex;
  
  overflow-y: scroll; 
  scroll-behavior: smooth;
  scroll-snap-type: y mandatory;
}

.more-details {
  background-color: #212529;
  color: #fff
}

.preview-game {
  background-color: #fff;
  width: 100%;
  justify-content:start;
}

.hide-buttons {
  animation: reveal .5s ease-in-out forwards; /* Use the reveal animation */
}

.more-details-title{
  font-size: 4rem;
  margin-bottom:20px;
  font-family: 'Games', sans-serif;
}

.more-details-body{
  font-size: 1.5rem;
}

.arrows {
  width: 60px;
  height: 72px;
}

.arrows-home-page{
  position: absolute;
  left: 50%;
  margin-left: -30px;
  bottom: 10px;
  stroke: #383636;
}

.arrows-more-details {
  stroke: #fff;
  margin-bottom: 0px;
}

.arrows path {
  fill: transparent;
  stroke-width: 2px;  
  animation: arrow 1s infinite;
  -webkit-animation: arrow 1.5s infinite; 
}

.arrows path.a1 {
  animation-delay:-1s;
  -webkit-animation-delay:-1s; /* Safari 和 Chrome */
}

@keyframes arrow
{
0% {opacity:0}
40% {opacity:1}
80% {opacity:0}
100% {opacity:0}
}

@-webkit-keyframes arrow /*Safari and Chrome*/
{
0% {opacity:0}
40% {opacity:1}
80% {opacity:0}
100% {opacity:0}
}

@import url('https://fonts.cdnfonts.com/css/games');
.site-name{
  height: 50vh;
  padding-top: 90px;
  width: 100%;
  font-family: 'Games', sans-serif;
  text-align: center;
  overflow: hidden; /* Hide overflowing text */
}

@keyframes reveal {
  0% {
      transform: translateX(-100%);
  }
  100% {
      transform: translateX(0);
      border-top: 3px solid #fff;
      border-bottom: 3px solid #fff;
  }
}

.hide-site-name{
  animation: reveal 1s ease-in-out forwards; /* Use the reveal animation */
  border-top: 1px solid #383636;
  border-bottom: 1px solid #383636;
  margin-left:100px ;
  margin-right:50px ;
}

.site-name-text{
  font-size: 7em;
  color: #383636;
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide overflowing text */
  padding-top: 27px;
}

.play-link-container {
  margin: auto;
  width: 200px;
  height: 8vh;
  text-align: center;
  box-sizing: border-box;
  border-color: rgb(68, 62, 62);
  border-radius: 3px;
  border: 1px solid;
  transition: background-color 0.3s
}

.play-link-container:hover {
  background-color: rgb(68, 62, 62);
  border-color: #fff;
  color: #fff;
  cursor: pointer;
}

.home-play-link{
  line-height: 8vh;
  text-decoration: none; /* no underline */
  color:inherit;
  font-family: 'Games', sans-serif;
}

.home-play-link:hover {
  color: #fff;
}

@media screen and (max-width: 800px) {
  .hide-site-name {
    margin-left: 0px;
    margin-right: 0px;
  }
  .preview-game{
    padding: 0px;
  }
  .site-name-text {
    font-size: 5em;
  }
  .home-section {
    padding: 50px 35px 0px 30px;
  }
  .btn {
    margin: 5px;
  }
  .keyboard-container {
    flex-wrap: wrap;
    margin-bottom: 0px;
  }
  .form-game-card {
    padding-top: 10vh;
  }
}
